body {
  font-family: 'Menlo', monospace;
  margin: 40px;
  color: #ffffff;
  background-color: #121615;
}

header, section, footer {
  margin-bottom: 20px;
}

header h1 {
  font-size: 24px;
}

header p, footer p {
  font-size: 14px;
}

section h2 {
  color: #555;
}

figure img {
  display: block; 
  width: auto; 
  height: 350px;
  padding: 5px; 
  object-fit: cover;
  object-position: center bottom;
}

.photo-collage {
  column-count: 3;
  column-gap: 20px;
  margin-bottom: 30px;
}

.photo-collage figure {
  margin: 0 0 20px 0;
  display: inline-block;
  width: 100%;
  break-inside: avoid;
}

.photo-collage img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

.photo-collage figcaption {
  margin-top: 8px;
  text-align: center;
  font-size: 12px;
}

.photo-collage video {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

@media screen and (max-width: 900px) {
  .photo-collage {
    column-count: 2;
  }
}

@media screen and (max-width: 600px) {
  .photo-collage {
    column-count: 1;
  }
}

a {
  color: #91d7ff;
}

li {
  margin-bottom: 15px; 
}

@media screen and (max-width: 600px) {
  body {
    margin: 20px;
  }

  header h1 {
    font-size: 20px;
  }
}